<template>
  <div>
    <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.3333 24H2.66667C1.93333 24 1.30578 23.7391 0.784 23.2173C0.262222 22.6956 0.000888889 22.0676 0 21.3333V2.66667C0 1.93333 0.261333 1.30578 0.784 0.784C1.30667 0.262222 1.93422 0.000888889 2.66667 0H21.3333C22.0667 0 22.6947 0.261333 23.2173 0.784C23.74 1.30667 24.0009 1.93422 24 2.66667V17.3333L17.3333 24ZM16 22V18.6667C16 17.9333 16.2613 17.3058 16.784 16.784C17.3067 16.2622 17.9342 16.0009 18.6667 16H22V2H2V22H16ZM7.5 18.6667H10.1667V12.8333H14V10.1667H9.83333V7.83333H16.5V5.33333H9.83333H7.5V18.6667Z" fill="currentColor"/>
    </svg>
  </div>
</template>

<style lang="scss" scoped>
@import "../colours";

@media screen and (max-width: 500px) {
  .responsive-hide {
    display: none;
  }
}

.part-icon {
  color: var(--colour-brand);
}

.part-name {
  color: var(--colour-text-muted);
}
</style>

<script>
export default {
  props: {
    responsive: { type: Boolean, default: false },
  },
};
</script>
