<template>
  <div class="d-flex justify-content-between align-items-center">

    <div class="d-flex gap-1 align-items-center">

      <!-- Logo -->
      <a
        :href="constants.basePaths.home"
        @click.prevent="navigate(constants.basePaths.home, $event)"
      >
        <Logo :_class="{ invisible: !showLogo }" responsive></Logo>
      </a>
      
      <button
        class="bttn m-0 p-1 d-flex align-items-center"
        :href="azHref"
        @click.prevent="navigate(azHref, $event)"
      >
      <b-icon icon="search"  class="m-1"></b-icon>
        <!-- <span>All Notes</span> -->
      </button>

      <a
       
        :href="constants.basePaths.new"
        class="bttn m-0 p-1 d-flex align-items-center"
        @click.prevent="navigate(constants.basePaths.new, $event)"
      >
        <b-icon icon="plus-circle" class="m-1"></b-icon>
      </a>

    </div>

    

    <!-- Buttons -->
    <div class="d-flex gap-1">
      
      <!-- Toggle Theme -->
      <button
          class="bttn m-0 p-1 d-flex align-items-center"
          @click="$emit('toggleTheme')"
        >
          <b-icon
            :icon="darkTheme ? 'sun' : 'moon'"
            class="m-1"
          >
          </b-icon>
          <!-- <span>Toggle Theme</span> -->
        </button>

        <!-- Log Out -->
        <button
          v-if="showLogOutButton"
          class="bttn m-0 p-1 d-flex align-items-center"
          @click="$emit('logout')"
        >
          <b-icon icon="box-arrow-right" class="m-1"></b-icon>
          <!-- <span>Log Out</span> -->
        </button>
      
    </div>
  </div>
</template>

<style lang="scss">
// Use visibility hidden instead of v-show to maintain consistent height
.invisible {
  visibility: hidden;
}
.gap-1 {
  gap: .3rem;
}
.menu {
  background-color: var(--colour-background);
  color: var(--colour-text);
  border: 1px solid var(--colour-border);

  hr {
    border-top: 1px solid var(--colour-border);
  }
}

.keyboard-shortcut {
  background-color: var(--colour-background-elevated);
  padding: 0.1em 0.75em;
  border: 1px solid var(--colour-border);
  border-radius: 4px;
  font-size: 0.75em;
}
</style>

<script>
import * as constants from "../constants";

import EventBus from "../eventBus";
import Logo from "./Logo";

export default {
  components: {
    Logo,
  },

  props: {
    showLogo: {
      type: Boolean,
      default: true,
    },
    authType: { type: String, default: null },
    darkTheme: { type: Boolean, default: false },
  },

  computed: {
    azHref: function () {
      let params = new URLSearchParams();
      params.set(constants.params.searchTerm, "*");
      params.set(constants.params.sortBy, constants.searchSortOptions.title);
      params.set(constants.params.showHighlights, false);
      return `${constants.basePaths.search}?${params.toString()}`;
    },

    showLogOutButton: function () {
      return (
        this.authType != null &&
        ![constants.authTypes.none, constants.authTypes.readOnly].includes(
          this.authType
        )
      );
    },

    showNewButton: function () {
      return (
        this.authType != null && this.authType != constants.authTypes.readOnly
      );
    },
  },

  methods: {
    navigate: function (href, event) {
      EventBus.$emit("navigate", href, event);
    },
  },

  created: function () {
    this.constants = constants;
  },
};
</script>
